import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

const initialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            // users: {
            //     id: {
            //         $containsi: '',
            //     },
            // },
            $or: [
                {
                    name: {
                        $containsi: '',
                    },
                },
                {
                    description: {
                        $containsi: '',
                    },
                },
                {
                    users: {
                        id: {
                            $containsi: '',
                        },
                    },
                },
            ],
        },
        populate: ['users'],
        pagination: {
            page: 1,
            pageSize: 5,
        },
    };
};

export default {
    namespaced: true,
    state: {
        medications: [],
        query: initialQuery(),
        isDeleteMedication: false,
    },
    getters: {
        getMedicationList(state) {
            return state.medications;
        },
        getMedicationQuery(state) {
            return state.query;
        },
        getMedication(state) {
            return state.medication;
        },
        getIsDeleteMedication(state) {
            return state.isDeleteMedication;
        },
    },
    mutations: {
        setMedicationList(state, medications) {
            state.medications = medications;
        },
        setMedicationQuery(state, query) {
            state.query = query;
        },
        resetMedicationQuery(state) {
            state.query = initialQuery();
        },
        setMedication(state, medicationData) {
            state.medicationData = medicationData;
        },
        setMedicationSearch(state, search) {
            state.query.filters.$or[0].name.$containsi = search;
            state.query.filters.$or[1].description.$containsi = search;
            state.query.filters.$or[2].users.id.$containsi = search;
        },
        setIsDeleteMedication(state, bol) {
            state.isDeleteMedication = bol;
        },
    },
    actions: {
        fetchMedicationList(ctx) {
            let _query = _.cloneDeep(ctx.state.query);

            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/medications?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchMedication(ctx, id, query = {}) {
            query.populate = ['users'];

            return new Promise((resolve, reject) => {
                axios
                    .get(`/medications/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updateMedication(ctx, data) {
            let query = qs.stringify({
                populate: ['users'],
            });
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;
                delete _data.id;
                axios
                    .put(`/medications/${id}?${query}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addMedication(ctx, medicationData) {
            medicationData = _.assign({}, medicationData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/medications', { data: medicationData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteMedication(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/medications/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteManyMedications(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/medications/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        /*         
export function deleteManyItems(query = {}) {
    query = qs.stringify(query);
    return axios.delete(`/items/many?${query}`);
} */
        /*   addSections(ctx, sectionsData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/parking-lots-sections', { data: sectionsData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
    },
};
